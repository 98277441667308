@use "../../../scss/utils/functions/"as*;
@use "../../../scss/utils/mixins/"as*;

$backgroundColor: #fff9ed;
$sectionPaddingsY: 86px;
$sectionPaddingsYMob: 50px;

.services-images {
  padding: $sectionPaddingsY 0;

  h2 {
    font-weight: 600;

    +p {
      max-width: 730px;
      margin-bottom: 64px;
      opacity: 0.64;
    }
  }

  .grid {
    gap: 24px;

    img {
      margin-bottom: 24px;
      border-radius: var(--radius-main);
      margin-inline: auto;
    }

    h3 {
      font-size: 24px;
      font-weight: 700;
      width: 220px;
      margin: 0 auto;
      text-align: center;
      line-height: 1.3;

      @include rmax(900) {
        font-size: 20px;
      }
    }

    @include rmax(1180) {
      display: flex;
    }

    @include rmax(768) {
      flex-wrap: wrap;
      justify-content: center;
    }
  }

}