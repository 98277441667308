@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

$linkColor: var(--color-black);
$linkColorOnHover: var(--color-primary);
$linkFontSize: 16px;
$gapBetweenLinks: 32px;
$outlineColor: var(--color-primary);
$menuRight: 46px;

.nav {
  // position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__inner {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    transition: 0.3s ease-in-out;

    @include rmax(1100) {
      position: absolute;
      left: 0;
      right: 0;
      width: 100%;
      // max-width: 320px;
      top: 50px;
      flex-direction: column;
      background-color: var(--color-white);
      gap: 20px;
      padding: 30px;
      transform: translateY(-200%);
      overflow-y: auto;
    }

    @include rhmax(600) {
      height: calc(100svh - 50px);

    }
  }

  &__list {
    display: flex;
    align-items: center;
    gap: $gapBetweenLinks;
    margin-right: $menuRight;

    @include rmax(1100) {
      flex-direction: column;
      background-color: var(--color-white);
      margin-right: 0;
      gap: 20px;

    }

    @include rhmax(600) {
      overflow-y: scroll;
    }
  }

  &__link {
    font-size: $linkFontSize;
    white-space: nowrap;
    text-transform: capitalize;
    color: $linkColor;
    background-color: transparent;
    border-radius: 3px;
    outline: 2px solid transparent;
    transition: 0.3s ease-in-out;

    &:focus {
      outline-color: $outlineColor;
      outline-offset: 3px;
    }

    @include hover {
      color: $linkColorOnHover;
    }

    &.true {
      color: $linkColorOnHover;
    }
  }

  &__btns {
    display: flex;
    align-items: center;
    gap: $gapBetweenLinks;

    @include rmax(1100) {
      flex-direction: column;
      gap: 20px;
    }

    .formsapp-popup-button {
      margin: 0 !important;
    }
  }
}

.open {
  & .site-container {

    // position: relative;
    // z-index: 10;
    &::before {
      background-color: rgba(#000, 0.75);
      z-index: 10;
    }
  }
}