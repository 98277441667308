@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.header {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 20;
  margin: 0 auto;
  padding: 25px 0;
  width: 100%;
  background-color: var(--color-white);
  transition-property: transform, padding;
  transition-duration: 0.15s;

  @include rmax(1100) {
    padding: 15px;
  }

  &.hide {
    transform: translateY(calc(-100% - 30px));
  }
}

.logo {
  flex: 1 0 auto;
  display: block;
  width: 171px;
}