@use "../../utils/functions/"as*;
@use "../../utils/mixins/"as*;

.intro {
  padding-bottom: 40px;

  h2,
  p {
    max-width: 770px;
    margin: 0 auto;
    text-align: center;
  }

  h2 {
    margin-bottom: 24px;
  }

  p {
    opacity: 0.64;
    margin-bottom: 64px;

    @include rmax(900) {
      margin-bottom: 30px;
    }
  }

  .intro-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(6, 1fr);
    gap: 30px;

    @include rmax(900) {
      gap: 10px;
    }

    img {
      border-radius: var(--radius-main);

      @include rmax(768) {
        border-radius: 10px;
      }
    }

    li:nth-child(4) {

      img {
        margin-top: -40px;

        @include rmax(786) {
          margin-top: -20px;
        }
      }
    }

    li:nth-child(6) {
      img {
        margin-top: -20px;

        @include rmax(900) {
          margin-top: -10px;
        }
      }
    }

    li:nth-child(1) {
      grid-area: 1 / 1 / 5 / 2;
    }

    li:nth-child(2) {
      grid-area: 5 / 1 / 7 / 2;
    }

    li:nth-child(3) {
      grid-area: 1 / 2 / 3 / 3;
    }

    li:nth-child(4) {
      grid-area: 3 / 2 / 7 / 3;
    }

    li:nth-child(5) {
      grid-area: 1 / 3 / 4 / 4;
    }

    li:nth-child(6) {
      grid-area: 4 / 3 / 7 / 4;
    }

  }

}