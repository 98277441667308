@use "../../utils/functions/"as*;
@use "../../utils/mixins/"as*;

$sectionPaddingsY: 86px;
$sectionPaddingsYMob: 50px;

.team {

  &__inner {
    position: relative;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: $sectionPaddingsY 0;

    @include rmax(900) {
      padding: $sectionPaddingsYMob 0;
    }

    &::before {
      content: '';
      position: absolute;
      inset: 0;
      background-color: var(--color-black);
      opacity: 0.95;
      z-index: 1;
    }
  }

  h2 {
    position: relative;
    z-index: 10;
    color: var(--color-white);

    margin: 0 auto;
    margin-bottom: 56px;

    @include rmax(600) {
      margin-bottom: 30px;
    }
  }

  &__grid {
    position: relative;
    background-color: var(--color-white);
    border-radius: var(--radius-main);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    z-index: 10;

    @include rmax(600) {
      flex-direction: column;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 70px 0;
    min-width: 260px;
    width: 33.3%;

    &:not(:last-child) {
      border-right: 2px solid #ebeaed;

      @include rmax(900) {
        border-right: 0;
      }
    }

    @include rmax(900) {
      padding: 30px 0;

      width: 33.3%;
    }

    @include rmax(600) {
      width: 100%;
    }


  }
}

.team-card {
  font-size: 22px;
  line-height: 1.5;
  text-align: center;

  @include rmax(600) {
    font-size: 18px;
  }

  img {
    object-fit: contain;
    border-radius: 50%;
    margin: 0 auto;
    margin-bottom: 32px;
  }

  h3 {
    font-size: 22px;
    width: 100%;
    font-weight: 600;
    margin-bottom: 10px;
  }

  span {
    color: var(--color-secondary);
  }
}