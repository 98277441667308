@use "../../../scss/utils/functions/"as*;
@use "../../../scss/utils/mixins/"as*;

.service-item {

  .container {
    display: grid;
    grid-template-columns: 605px 570px;
    align-items: center;

    @include rmax(1100) {
      grid-template-columns: 1fr 1fr;
    }


    @include rmax(768) {
      grid-template-columns: 1fr;

    }
  }

  &:not(:last-child) {
    @include rmax(768) {
      margin-bottom: 30px;
    }
  }

  &.reversed {
    .service-item__img {
      transform: translateX(95px);
      order: 2;

      @include rmax(1100) {
        transform: translateX(10%);
      }

      @include rmax(768) {
        transform: translateX(0);
        order: 0;
      }
    }
  }

  &__img {
    flex: 1 0 auto;
    object-fit: cover;
    transform: translateX(-135px);
    width: 100%;
    // max-width: 600px;
    width: 605px;
    height: 905px;

    @include rmax(1100) {
      transform: translateX(-10%);
      width: 100%;
      height: auto;
    }

    @include rmax(768) {
      transform: translateX(0);
      margin-bottom: 30px;
      height: 200px;
      width: 100%;
      max-width: 100%;
      object-position: center;
    }
  }

  &__content {
    // transform: translateX(-30px);
    max-width: 570px;

    @include rmax(768) {
      max-width: 100%;
    }

    h2 {
      font-size: clamp(2.25rem, 1.9951rem + 1.0458vi, 48px);
      line-height: 1.3;
      margin-bottom: 24px;
    }

    p {
      opacity: 0.64;
    }
  }

  &--description {

    h2 {
      margin-bottom: 24px;
    }

    h3 {
      color: var(--color-secondary);
      font-size: 20px;
      margin-bottom: 16px;
    }

    p {
      font-size: 20px;
      opacity: 0.64;
      margin-bottom: 24px;
    }

    .service-item__img {
      // object-position: top  center;
      // height: 300px;
    }
  }

  &.career-first {
    padding: 120px 0;

    @include rmax(900) {
      padding: 50px 0;
    }

    .service-item__img {
      // height: 300px;
      transform: translateX(0);
      border-radius: var(--radius-main);
      object-position: top center;
    }

    .service-item__content {
      max-width: 470px;

      @include rmax(768) {
        max-width: 100%;
      }
    }
  }
}