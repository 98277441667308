@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

h1,
h2,
h3 {
  width: fit-content;
  font-weight: var(--fw-600);
  line-height: 1.1;
  color: var(--color-black);
}

h1 {
  font-size: clamp(2.25rem, 1.9951rem + 1.8vi, 56px);

  @include media(mobile-l) {
    font-size: 34px;
  }
}

h2,
.title {
  margin-bottom: rem(24);
  font-size: clamp(2rem, 1.7143rem + 1.4286vi, 48px);
  line-height: 1.3;
}

h3 {
  font-size: 32px;
}

p {
  font-size: 20px;
  line-height: 1.6;
}