@use "../../../scss/utils/functions/"as*;
@use "../../../scss/utils/mixins/"as*;

$heroMinHeight: 500px;
$heroContentWidth: 650px;

$h1MarginBottom: 30px;

$pFontSize: 20px;
$pLineHeight: 1.6;
$pletterSpacing: 0;
$pMarginBottom: 32px;

$btnPaddingY: 20px;
$btnPaddingX: 32px;

.hero {
  &__inner {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 500px;
    height: calc(100vh);
    height: calc(100svh);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-attachment: fixed;

    @include rmax(900) {
      height: calc(100vh);
      height: calc(100svh);
    }

    &::before {
      content: '';
      position: absolute;
      display: block;
      inset: 0;
      background-image: var(--main-gradient);

    }

    @include rhmin(900) {
      min-height: $heroMinHeight;
    }
  }

  &__content {
    position: relative;
    z-index: 1;
    height: 100%;
    max-width: $heroContentWidth;
    margin: 0 auto;
    text-align: center;

    h1 {
      margin: 0 auto;
      margin-bottom: $h1MarginBottom;
    }

    p {
      margin-bottom: $pMarginBottom;
      font-size: $pFontSize;
      line-height: $pLineHeight;
    }

    .btn {
      display: inline-block;
      width: fit-content;
      padding: $btnPaddingY $btnPaddingX;
    }
  }

}