@use "../../utils/functions/"as*;
@use "../../utils/mixins/"as*;

$mainTextColor: var(--color-black);
$gridFirstCol: 570px;
$gridGap: 30px;
$sectionPaddingsY: 86px;

.featured {
  padding: $sectionPaddingsY 0;
  color: $mainTextColor;

  @include rmax(900) {
    padding: 50px 0;
  }

  &__inner {
    display: grid;
    grid-template-columns: $gridFirstCol 1fr;
    gap: $gridGap;
    align-items: center;

    @include rmax(1100) {
      grid-template-columns: 1fr 1fr;
    }

    @include rmax(900) {
      grid-template-columns: 1fr;
    }

  }

  &__content {

    h2 {
      line-height: 1.2;
      margin-bottom: 24px;
    }

    &>p {
      margin-bottom: 24px;
      opacity: 0.64;

      @include rmax(900) {
        font-size: 16px;
      }
    }

  }

  &__picture {
    img {
      width: 573px;

      @include rmax(900) {
        width: 100%;
        max-width: 450px;
        margin: 0 auto;
      }

    }
  }
}

.featured-list {

  &__item {
    display: flex;
    align-items: center;
    position: relative;
    gap: 32px;

    &:not(:last-child) {
      margin-bottom: 24px;
    }

    &::before {
      content: '';
      min-width: 86px;
      height: 86px;
      border-radius: 32px;
      background-color: var(--color-primary);
      background-image: var(--icon-featured-1);
      background-size: 42px;
      background-position: center;
      background-repeat: no-repeat;
    }

    h3 {
      font-size: 20px;
      font-weight: 600;
      line-height: 1;
      margin-bottom: 10px;
    }

    p {
      font-size: 16px;
      opacity: 0.64;
    }

    &:nth-child(2) {
      &::before {
        background-image: var(--icon-featured-2);
      }
    }

    &:nth-child(3) {
      &::before {
        background-image: var(--icon-featured-3);
      }
    }
  }
}