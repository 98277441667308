@use "../../../scss/utils/functions/"as*;
@use "../../../scss/utils/mixins/"as*;

.feature-item {

  position: relative;
  color: var(--color-white);

  &.reversed {
    text-align: right;

    .container {
      justify-content: flex-end;
    }

    h2 {
      width: 100%;
      text-align: right;
    }
  }

  .container {
    display: flex;
  }

  &__inner {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding-top: 135px;
    padding-bottom: 50px;
    position: relative;
    min-height: 500px;
    height: 952px;
    display: flex;
    z-index: 10;

    @include rmax(1100) {
      height: 100svh;
    }

    @include rmax(900) {
      min-height: 200px;
      height: 50svh;
      padding: 50px 0;
    }

    @include rmax(768) {
      height: 300px;
      min-height: 200px;
    }

    &::before {
      content: '';
      position: absolute;
      inset: 0;
      background-image: linear-gradient(180deg, rgba(51, 35, 0, 1) 0%, rgba(199, 146, 35, 1) 100%);
      opacity: 0.5;
    }
  }

  &__content {
    position: relative;
    z-index: 10;
    max-width: 695px;

    h2 {
      color: inherit;
      font-weight: bold;
      line-height: 1.2;
      font-size: 58px;
      letter-spacing: -0.02em;

      @include rmax(900) {
        // font-size: 30px;
        font-size: 44px;
      }

      @include rmax(768) {
        // font-size: 22px;
        font-size: 30px;
      }

      @include rmax(480) {
        font-size: 22px;
      }
    }

    p {
      color: inherit;
      font-size: 22px;
      line-height: 1.45;

      @include rmax(768) {
        font-size: 16px;
      }
    }
  }

  &.first {
    h2 {
      max-width: 380px;
    }
  }
}