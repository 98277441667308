@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

$sectionpaddingsy: 86px;
$sectionpaddingsymob: 50px;

.contact {
  padding: $sectionpaddingsy 0;

  @include rmax(900) {
    padding: $sectionpaddingsymob 0;
  }

  &__wrapper {
    width: 100%;
    max-width: 570px;
    margin: 0 auto;

    h2 {
      width: 100%;
      font-size: clamp(2.25rem, 1.9951rem + 1.0458vi, 48px);
      margin-bottom: 50px;
      text-align: center;

      @include rmax(900) {
        margin-bottom: 30px;
      }
    }
  }

  &__bottom {
    display: flex;
    gap: 24px;

    @include rmax(600) {
      gap: 15px;
      flex-wrap: wrap;
    }
  }

  &__form {
    width: 100%;
    margin-bottom: 50px;

    @include rmax(900) {
      margin-bottom: 30px;
    }
  }

  label {
    display: block;
    width: 100%;
  }

  input,
  textarea {
    border: 2px solid #ebebeb;
    border-radius: 10px;
    padding: 13px 20px;
    width: 100%;
    color: #bdbdbd;
    font-size: 18px;
    background-color: var(--color-white);
    outline: 2px solid transparent;
    transition: 0.3s ease-in-out;

    &:focus {
      outline-offset: 3px;
      outline-color: var(--color-primary);
    }
  }

  input {
    display: block;
    width: 100%;
    border-radius: var(--radius-main);
    padding: 15px 24px;
  }

  textarea {
    min-height: 170px;
    resize: none;
    margin-bottom: 30px;

    @include rmax(900) {
      margin-bottom: 15px;
    }
  }

  .btn {
    white-space: nowrap;
    border-radius: 40px;
  }

  &__info {
    h3 {
      font-size: 14px;
      letter-spacing: 0.02em;
      width: 100%;
      text-transform: uppercase;
      margin-bottom: 20px;
    }

    a {
      font-size: 16px;
      border-radius: 3px;
      outline: 2px solid transparent;
      transition: 0.3s ease-in-out;

      @include hover() {
        color: var(--color-primary);
      }

      &:focus {
        outline-offset: 3px;
        outline-color: var(--color-primary);
      }
    }

    text-align: center;
  }

}