@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.footer {
  color: var(--color-white);
  background-color: var(--color-black);

  .container {
    max-width: 1250px;
  }

  .logo {
    img {
      filter: brightness(166%);
    }
  }

  &__top,
  &__bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    flex-wrap: wrap;

  }

  &__email {
    display: block;
    font-size: 20px;
    margin-bottom: 24px;

    @include rmax(900) {
      font-size: 16px;
    }
  }

  &__top {
    position: relative;
    padding: 55px 0;
    gap: 30px;

    &::after {
      content: '';
      position: absolute;
      width: 100vw;
      left: calc(((100% - 100vw) / 2));
      top: 100%;
      height: 1px;
      background-color: rgba(#fff, 0.2);
    }
  }

  &__bottom {
    padding: 25px 0;
  }

  &__nav,
  &__actions {
    display: flex;
    align-items: center;
    gap: 32px;
  }

  &__nav {
    gap: 48px;

    a {
      font-size: 16px;
      font-weight: bold;
    }
  }

  .btn {
    margin: 0 !important;
  }

  .copyright {
    font-size: 16px;
  }

  @include rmax(768) {

    &__top,
    &__bottom {
      justify-content: center;
    }

    .logo {
      margin: 0 auto;
    }
  }
}