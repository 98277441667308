@use "../../../scss/utils/functions/"as*;
@use "../../../scss/utils/mixins/"as*;

$sectionPaddingsY: 86px;
$sectionPaddingsYMob: 50px;
$sectionBgColor: var(--color-primary);
$sectionTextColor: var(--color-white);

.reviews {
  padding: $sectionPaddingsY 0;
  color: $sectionTextColor;

  @include rmax(900) {
    padding: $sectionPaddingsYMob 0;
  }

  &--bg {
    background-color: $sectionBgColor;
  }

  &__grid {
    display: flex;
    justify-content: space-between;
    gap: 50px;
    // flex-wrap: wrap;

    @include rmax(900) {
      flex-wrap: wrap;
      gap: 30px;
      justify-content: center;
    }
  }

  &__item {
    display: flex;
    gap: 24px;
    align-items: flex-start;

    @include rmax(600) {
      flex-direction: column;
      align-items: center;
    }

  }

  &__photo {
    border-radius: 50%;
    min-width: 70px;
    height: 70px;
    transform: translateY(10px);
  }

  &__content {
    max-width: 380px;

    h3 {
      font-size: 14px;
      text-transform: uppercase;
      font-weight: bold;
      color: inherit;
    }

    p {
      font-size: 22px;
      font-weight: 500;
      margin-bottom: 20px;
      line-height: 1.4;

      @include rmax(900) {
        font-size: 18px;
      }
    }
  }

  h2 {
    margin-bottom: rem(45);
  }


}