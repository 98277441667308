/* stylelint-disable declaration-no-important */
@use "../utils/functions/"as*;
@use "../utils/mixins"as*;

$backgroundColor: var(--color-primary);
$bgOnHover: transparent;

$textColor: var(--color-white);
$textOnHover: var(--color-black);

$borderColor: transparent;
$borderColorOnHover: var(--color-primary);

$fontWeigth: var(--fw-500);
$paddingY: 16px;
$paddingX: 24px;
$fontSize: 16px;

$borderWidth: 1px;


*[class].btn {
  border-radius: calc(var(--radius-main) - 4px);
  padding: $paddingY $paddingX;
  font-weight: $fontWeigth;
  font-size: $fontSize;
  line-height: 1;
  text-align: center;
  color: $textColor;
  border: $borderWidth solid $borderColor;
  background-color: $backgroundColor;
  outline: 2px solid transparent;
  transition-property: background-color, color, border-color, outline;
  transition-duration: 0.2s;

  &:focus {
    outline-color: $borderColorOnHover;
    outline-offset: 3px;
  }

  &:hover {
    border-color: $borderColorOnHover !important;
    color: $textOnHover !important;
    background-color: $bgOnHover;
  }
}


button.formsapp-popup-button {
  margin: 0 !important;
}