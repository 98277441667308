/* stylelint-disable alpha-value-notation */
/* stylelint-disable color-function-notation */
@use "./../utils/functions/"as*;
@use "./../utils/mixins/"as*;

:root {
  --content-width: 1170px;
  --container-offset: 15px;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));

  // font-family
  --font-family-primary: "Inter", sans-serif;

  // font-weight
  --fw-400: 400;
  --fw-500: 500;
  --fw-600: 600;
  --fw-700: 700;

  // color
  --color-white: #fff;
  --color-black: #312A27;

  --color-dark-800: #312A27;

  --color-primary: #E1A932;
  --color-secondary: #936400;
  //filter

  //  radius
  --radius-main: 32px;

  // shadow

  // gradient
}