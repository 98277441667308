@use "../../../scss/utils/functions/"as*;
@use "../../../scss/utils/mixins/"as*;

$backgroundColor: #fff9ed;
$sectionPaddingsY: 86px;
$sectionPaddingsYMob: 50px;

.service {
  padding: $sectionPaddingsY 0;
  background-color: $backgroundColor;

  @include rmax(900) {
    padding: $sectionPaddingsYMob 0;
  }

  h2 {
    font-weight: 600;
    + p {
      max-width: 730px;
      margin-bottom: 64px;
      opacity: 0.64;
    }
  }

  .grid {

    &__item {
      padding: 40px 47px;
      padding-top: 120px;
      border-radius: var(--radius-main);
      border: 2px solid var(--color-secondary);
      background-image: var(--icon-service-1);
      background-repeat: no-repeat;
      background-size: 52px;
      background-position: top 40px center;
      text-align: center;

      @include rmax(900) {
        padding: 30px;
        padding-top: 90px;
        background-position: top 20px center;
        max-width: 360px;
        margin: 0 auto;
      }

      h3 {
        font-weight: 700;
        font-size: 24px;
        max-width: 221px;
        margin: 0 auto;
        // text-align: center;
        margin-bottom: 24px;
      }

      p {
        font-size: 16px;
        margin-bottom: 28px;
        line-height: 1.6;
      }

      a {
        display: block;
        width: fit-content;
        margin-inline: auto
      }

      &:nth-child(2) {
        background-image: var(--icon-service-2);
      }

      &:nth-child(3) {
        background-image: var(--icon-service-3);
      }
    }

    @include rmax(1180) {
      display: flex;
    }

    @include rmax(900) {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
    }
  }

  &.service-fleet {

    .btn {
      display: none;
    }

    .grid__item {

      &:nth-child(1) {
        background-image: var(--icon-service-4);
      }

      &:nth-child(2) {
        background-image: var(--icon-service-5);
      }

      &:nth-child(3) {
        background-image: var(--icon-service-6);
      }
    }
  }

}
