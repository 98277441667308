@use "../../utils/functions/"as*;
@use "../../utils/mixins/"as*;

$sectionPaddingsYDesktop: 121px;
$sectionPaddingsYMob: 60px;

$mainTextColor: var(--color-white);
$secondaryTextColor: var(--color-primary);

.choose {

  color: $mainTextColor;



  &__inner {
    position: relative;
    padding: $sectionPaddingsYDesktop 0;
    background-size: cover;

    @include rmax(900) {
      padding: $sectionPaddingsYMob 0;
    }

    &::before {
      content: '';
      display: block;
      position: absolute;
      inset: 0;
      background-color: var(--color-black);
      opacity: 0.95;
    }
  }

  &__container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    gap: 30px;
    position: relative;
    z-index: 1;

    @include rmax(900) {
      justify-content: center;
    }
  }

  h2 {
    font-weight: 600;
    font-size: 32px;
    color: inherit;

    @include rmax(900) {
      margin-inline: auto;
      text-align: center;
    }
  }

  &__content {
    max-width: 470px;
  }

  &__grid {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-left: 25px;
  }

  &__item {
    list-style-type: disc;

    &::marker {
      font-size: 20px;
      color: $secondaryTextColor;
    }


  }

  &__box {
    line-height: 1.6;
    font-size: 20px;

    h3 {
      font-size: inherit;
      display: inline;
      color: $secondaryTextColor;
      font-weight: 700;
    }

  }

  &__note {
    background-color: var(--color-white);
    padding: 64px 32px;
    font-size: 20px;
    width: 370px;
    color: initial;
    text-align: center;
    border-radius: var(--radius-main);
  }

}